import "./simple-page-topper.scss";

import cn from "clsx";
import { EditAttributes } from "design-system/types/types";
import { SimpleTheme } from "design-system/utils/theme";
import { ReactNode } from "react";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import { shouldDecreaseTextSize } from "design-system/utils/text";
import {
  CtaLink,
  CtaLinkProps,
} from "design-system/components/primitives/cta-link/cta-link";

export interface SimplePageTopperProps {
  title?: string;
  intro?: ReactNode;
  theme?: SimpleTheme;
  cta?: CtaLinkProps;
  media?: MediaAssetProps;

  editAttributes?: {
    title?: EditAttributes;
    subtitle?: EditAttributes;
  };
}

/**
 * ## See it in use on...
 * - The [kitchen sink detail page](/story/example-pages-detail-pages-kitchen-sink--story)
 *
 * - **`id: CT-001-003-00`**
 * - **`data-region: ct__page__simple`**
 */

export function SimplePageTopper({
  media,
  title,
  intro,
  cta,
  theme,
  editAttributes,
}: SimplePageTopperProps) {
  if (!title) return null;

  const titleClass = cn(
    "hbs-simple-page-topper__title",
    shouldDecreaseTextSize(title, 60, 20) &&
      "hbs-simple-page-topper__title--long",
    media && "hbs-simple-page-topper__title-media",
  );

  return (
    <div
      className="hbs-simple-page-topper hbs-topper"
      data-theme={theme || "white"}
      data-region="ct__page__simple"
    >
      <div className="hbs-simple-page-topper__content">
        {title && (
          <h1 {...editAttributes?.title} className={titleClass}>
            {title}
          </h1>
        )}

        {(intro || cta) && (
          <div
            className={cn(
              "hbs-simple-page-topper__text",
              media && "hbs-simple-page-topper__text--with-image",
            )}
          >
            {intro && (
              <div
                {...editAttributes?.subtitle}
                className="hbs-simple-page-topper__intro"
              >
                {intro}
              </div>
            )}
            {cta && (
              <div className="hbs-simple-page-topper__cta">
                <CtaLink type="primary-button" {...cta} />
              </div>
            )}
          </div>
        )}

        {media && (
          <MediaAsset
            {...media}
            className="hbs-simple-page-topper__title-media"
          />
        )}
      </div>
    </div>
  );
}
